<template>
	<div class="summer">
		<div class="summer_tab">
			<div @click="backTo" class="summer_back">
				<img src="@/assets/images/knowledgeBase/back.png">
				<span>返回</span>
			</div>
			<module-list :moduleList="moduleList" :currentTab="currentTab" @changeTab="changeTab" />
		</div>
		<div class="summer_content">
			<div class="summer_content_title">
				{{visitType == 'add' ? '添加夏校' : '编辑夏校'}}
			</div>
			<div class="summer_content_form">
				<div class="handle_form">
					<el-form ref="summerDetail" :rules="summerRules" :model="summerDetail" label-width="80px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="title" label="夏校标题:" ref="title">
									<input v-model="summerDetail.title" @blur="inputBlur($event)" placeholder="请输入夏校标题"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="cover" label="封面图片:" ref="uploadCover">
									<div style="margin-bottom: 6px;">
										<dragUpLoad ref="dragUpLoad" :isLimitPx="true" @beforeUpload="beforeUpload" @removeImg="removeImg" />
									</div>
								    <span style="color: #888888;">注：图片宽高限制最小为（340x170），其他格式请保持宽高比例为2:1，否则无法上传！</span>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" prop="content" label="内容:" ref="editor">
									<quillEditor @editorChange="editorChange" :intEditor.sync="summerDetail.content" class="handle_form_editor" />
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="backTo" class="handle_form_cancel">取消</span>
						<span @click="submitSummer" class="handle_form_confirm">发布</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moduleList from "@/components/moduleList/index.vue";
	import quillEditor from "@/components/quillEditor/index.vue";
	import dragUpLoad from "@/components/dragUpLoad/index.vue";
	import storage from 'store';
	import {
		addSummer,
		editSummer,
		detailSummer
	} from "@/api/knowledgeBase"
	export default {
		components: {
			moduleList,
			quillEditor,
			dragUpLoad
		},
		data() {
			const checkCover = (rule, value, callback) => {
				if (this.summerDetail.cover == '') {
					callback(new Error("请上传封面图片"));
				} else {
					callback();
				}
				callback();
			};
			const checkContent = (rule, value, callback) => {
				if (this.summerDetail.content == '') {
					callback(new Error("请输入内容"));
				} else {
					callback();
				}
				callback();
			};
			return {
				currentTab: '',
				moduleList: [],
				visitType: "",
				schoolId:"",
				imgSrc:[],
				summerDetail: {
					title: "",
					cover: "",
					content: ""
				},
				summerRules: {
					title: [{
						required: true,
						message: '请输入夏校标题',
						trigger: 'change'
					}],
					cover: [{
						required: true,
						validator: checkCover,
						trigger: 'change'
					}],
					content: [{
						required: true,
						validator: checkContent,
						trigger: 'change'
					}]
				},
				summerType: []
			}
		},
		computed: {},
		created() {
			this.currentTab = this.$route.query.currentTab;
			this.visitType = this.$route.query.visitType;
			this.schoolId = this.$route.query.schoolId;
			if (storage.get("moduleList")) {
				this.moduleList = storage.get("moduleList")
			}
			if(this.visitType == 'edit'){
				this.getSummerDetail(this.schoolId)
			}
		},
		mounted() {},
		methods: {
			//根据id获取详情信息
			getSummerDetail(id){
				detailSummer(id).then(res=>{
					if(res.code == 0){
						this.summerDetail = res.data
						// this.summerDetail.cover = [res.data.cover]
						this.$refs.dragUpLoad.fileData = [res.data.cover]
					}
				})
			},
			backTo() {
				this.$router.push({
					path: `/knowledgeBase?currentTab=${this.currentTab}`,
				});
				// this.$nextTick(() => {
				// 	this.$refs.summerDetail.resetFields()
				// 	this.$refs.summerDetail.clearValidate()
				// })
			},
			changeTab(props) {
				this.currentTab = props.label;
				this.backTo()
			},
			beforeUpload(props) {
				console.log(props,'propsqweqw')
				if (props.length > 0) {
					this.summerDetail.cover = props[0]
					this.$refs.uploadCover.clearValidate()
				} else {
					this.summerDetail.cover = ""
				}
			},
			removeImg(props) {
				if (props.length > 0) {
					this.summerDetail.cover = props[0]
				} else {
					this.summerDetail.cover = ""
				}
			},
			editorChange(props) {
				console.log("props", props)
				this.summerDetail.content = props
				if(props){
					this.$refs.editor.clearValidate()
				}
			},
			inputBlur(event){
				if(event.target.value!==''){
					this.$refs.title.clearValidate()
				}
			},
			submitSummer() {
				this.$refs["summerDetail"].validate(valid => {
					if (valid) { 
						let summerForm = JSON.parse(JSON.stringify(this.summerDetail))
						if (this.visitType == 'add') {
							addSummer(summerForm).then((res) => {
								if(res.code == 0) {
									this.$message({
										message: "夏校添加成功~",
										type: 'success'
									})
									setTimeout(() => {
										this.backTo()
									}, 500)
								}
							})
						}else{
							editSummer(summerForm,this.schoolId).then((res) => {
								if(res.code == 0) {
									this.$message({
										message: "夏校编辑成功~",
										type: 'success'
									})
									setTimeout(() => {
										this.backTo()
									}, 500)
								}
							})
						}
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";
	@import "./components/common.scss";

	.summer {
		display: flex;
		flex-direction: column;

		.summer_tab {
			padding-left: 932px;
			position: relative;

			.summer_back {
				position: absolute;
				left: 0;
				top: 11px;
				width: 100px;
				height: 34px;
				background: #FFFFFF;
				box-shadow: 0px 3px 6px rgba(153, 153, 153, 0.16);
				border-radius: 0px 17px 17px 0px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}

				span {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;
				}
			}

			.summer_back :hover {
				color: $theme_color;
			}
		}

		.summer_content {
			background: #FFFFFF;
			border-radius: 10px 10px 0px 0px;
			padding: 20px 20px;
			display: flex;
			flex-direction: column;

			.summer_content_title {
				font-size: 20px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				line-height: 20px;
				color: #333333;
				padding-left: 20px;
				border-left: 4px solid $theme_color;
			}

			.summer_content_form {
				.handle_form_row {
					margin-top: 25px;
				}
			}
		}
	}
</style>
